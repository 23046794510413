import debounce from 'lodash/debounce';
import type { FC, ReactNode } from 'react';
import React, { useEffect, Suspense } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import UFOSegment from '@atlaskit/react-ufo/segment';

import { useExposeGoogleMeetScreenShare } from '@confluence/embedded-google-meet/entry-points/useExposeGoogleMeetScreenShare';
import { ContentHeader } from '@confluence/content-header';
import type { BaseViewPageProps } from '@confluence/view-page-common';
import {
	useViewPageParams,
	ViewPageCommon,
	StartViewPageLoad,
	useViewPagePersistentScrollPosition,
} from '@confluence/view-page-common';
import { useSpaceId } from '@confluence/space-utils';
import { waitForGlobal } from '@confluence/wrm';
import { pagePublishedState } from '@confluence/editor-features';
import { getCachedPageData } from '@confluence/live-pages-utils/entry-points/getCachedPageData';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { useSearchSessionId } from '@confluence/search-session';
import { fg } from '@confluence/feature-gating';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import type { SuspenseableDataFetcher } from '@confluence/query-preloader-tools';
import { getDataResourceForSuspense } from '@confluence/query-preloader-tools';

import { LegacyBridgeNext } from './LegacyBridgeNext';

export interface ViewPageProps extends BaseViewPageProps {
	classicComments?: React.ComponentType<any> | undefined;
	ClassicEditorContextProviderLoader?: React.ComponentType<any>;
	children?: ReactNode;
	viewPageDataResource?: any;
}

const ViewPageInternal: FC<ViewPageProps> = ({
	contentId,
	spaceKey,
	classicComments,
	// Pass ClassicEditorContextProviderLoader from parent classic package when ViewPageCommon may need to render LivePageComponent.
	// This is passed down from classic package instead of imported in LivePageComponent because we don't want to import from classic package when in next, it causes circular dependency.
	ClassicEditorContextProviderLoader,
	children,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { versionOverride } = useViewPageParams();
	const [{ searchSessionId, additionalAnalytics }] = useSearchSessionId();
	const { isLivePagesFeatureEnabled } = useIsLivePagesFeatureEnabled();
	const isLivePage = useIsCurrentPageLive();
	useViewPagePersistentScrollPosition(contentId);

	let viewPageDataResource: SuspenseableDataFetcher | null = null;
	if (useRenderServerPlaceholder() && !isLivePage && fg('confluence_react_streaming')) {
		viewPageDataResource = getDataResourceForSuspense('preloadContentAndByline');
	}

	let contentHeader: React.ReactNode = null;
	if (contentId && spaceKey) {
		contentHeader = (
			<ContentHeader
				spaceKey={spaceKey}
				contentId={contentId}
				// @ts-ignore - Type 'number | null' is not assignable to type 'number | undefined'
				// This error was introduced after upgrading to TypeScript 5
				versionOverride={versionOverride}
			/>
		);
	}

	const spaceId = useSpaceId(spaceKey);

	useEffect(() => {
		// event is triggered by legacy JS when a new jira issue is created via JIM dialog
		waitForGlobal({ listener: 'ViewPage', globalProperty: 'AJS.bind' }, () => {
			window.AJS.bind('createGASv3Event', (_, data) => {
				createAnalyticsEvent(data).fire();
			});
		});

		if (pagePublishedState.pageJustPublished) {
			pagePublishedState.pageJustPublished = false;
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'viewed',
					actionSubject: 'pagePublished',
					source: 'viewPage',
					objectId: contentId,
					containerId: spaceId,
					containerType: 'space',
				},
			}).fire();
		}

		return () => {
			waitForGlobal({ listener: 'ViewPage', globalProperty: 'AJS.unbind' }, () => {
				window.AJS.bind('createGASv3Event');
			});
		};
	}, [createAnalyticsEvent, contentId, spaceId]);

	// Adds the `page scrolled (client)` instrumentation event to be used in the new metric definition for *engaged* users.
	useEffect(() => {
		const handleScroll = () => {
			const { scrollX, scrollY, innerWidth, innerHeight } = window;
			const { scrollWidth, scrollHeight } = document.documentElement;

			// The user has reached the bottom of the page if `scrollY + innerHeight` equals `scrollHeight`.
			const attributes = {
				scrollX,
				scrollY,
				innerWidth,
				innerHeight,
				scrollWidth,
				scrollHeight,

				// The depths are rounded to two decimals; that's enough precision for scrolling.
				depthX: Math.trunc(((scrollX + innerWidth) / scrollWidth) * 100) / 100,
				depthY: Math.trunc(((scrollY + innerHeight) / scrollHeight) * 100) / 100,
				searchSessionId,
				...additionalAnalytics,
			};
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'scrolled',
					actionSubject: 'page',
					source: 'viewPage',
					objectId: contentId,
					containerId: spaceId,
					containerType: 'space',
					attributes,
				},
			})?.fire(); // used optional chaining so that scroll event can be tested - mocking fire causes infinite loop
		};

		// Once Safari supports the `scrollend` event we can remove the `debounce` logic on the `scroll` event and use `scrollend` intead.
		// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event
		const debounceScroll = debounce(handleScroll, 250 /*ms*/);
		document.addEventListener('scroll', debounceScroll);
		return () => {
			document.removeEventListener('scroll', debounceScroll);
		};
	}, [createAnalyticsEvent, contentId, spaceId, searchSessionId, additionalAnalytics]);

	const cachedPageData = isLivePagesFeatureEnabled
		? getCachedPageData(contentId)
		: { subType: undefined, isArchived: undefined, gqlType: undefined };

	useExposeGoogleMeetScreenShare();

	const viewPageContent = (
		<>
			<StartViewPageLoad contentId={contentId} />
			<ViewPageCommon
				viewPageDataResource={viewPageDataResource}
				spaceKey={spaceKey}
				contentId={contentId}
				contentHeader={contentHeader}
				classicComments={classicComments}
				hasPageComments
				hasLabels
				hasReactions
				hasInlineComments
				hasEOPRecs
				ClassicEditorContextProviderLoader={ClassicEditorContextProviderLoader}
				cachedPageData={cachedPageData}
			>
				{children}
				<LegacyBridgeNext />
			</ViewPageCommon>
		</>
	);

	if (!isLivePage && fg('confluence_react_streaming')) {
		return <Suspense fallback={null}>{viewPageContent}</Suspense>;
	}

	return viewPageContent;
};

export const ViewPage: typeof ViewPageInternal = (props) => {
	return (
		<UFOSegment name="view-page">
			<ViewPageInternal {...props} />
		</UFOSegment>
	);
};
